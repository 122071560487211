
import React, { useState, useContext, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Row, Col, Input, InputGroup, Button, CardTitle, CardHeader, CardFooter, UncontrolledAlert } from "reactstrap";

import { Link } from 'react-router-dom';
import Select from "react-select";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { MoondanceContext } from '../../App.js';
import MoondanceLayout from "../lib/layout.js";
import UseMoondanceApi from "../lib/api.js";
import { buildAccountParameter, formatOptionLabel, buildCurrencyList, } from "../lib/formatData.js";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { useNavigate } from 'react-router-dom';

import Slider from '@mui/material/Slider';
import { set } from "lodash";
import { use } from "i18next";


const EditPositionForm = (props) => {
    const [model_pattern, set_model_pattern] = useState('linear');

    const [user_data, setUserData] = useState([]);

    const [show_essentials, set_show_essentials] = useState((props && props.show_essentials ? props.show_essentials : false));
    const [show_details, set_show_details] = useState(props && props.editMode !== 'New' ? (props && props.show_details ? props.show_details : false) : true);
    const [show_model, set_show_model] = useState(props && props.editMode !== 'New' ? (props && props.show_model ? props.show_model : false) : true);

    const navigate = useNavigate();
    const moondanceApi = UseMoondanceApi();
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const [assetList, setAssetList] = useState([]);

    // used to find marching positions
    const [matching_position_list, set_matching_position_list] = useState([]);
    const [matching_position_update, set_matching_position_update] = useState([]);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const [selected_position_details, set_selected_position_details] = useState(null);

    //used for date selector
    const flatpickrRef = useRef(null);
    const flatpickrRef2 = useRef(null);

    //Used for notification
    const { addAlert } = useContext(MoondanceContext);
    const [createNewAsset, setCreateNewAsset] = useState(false)

    // 
    const customSelectPattern = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#0bb197' : 'white',
            color: state.isFocused ? 'white' : 'black',
        }),

    };



    /* Generic lists ***********************************************************/
    const [currencyList, setCurrencyList] = useState([{ id: 'EUR', value: 'EUR', label: 'EUR' }]);
    const [selectedCurrency, setSelectedCurrency] = useState(userCurrency);

    const [editThisAsset, setEditThisAsset] = useState(true)
    const [positionLinkedToOtherAccountAsset, setPositionLinkedToOtherAccountAsset] = useState(false)
    const [assetSelectList, setAssetSelectList] = useState([]);

    const { accountList } = useContext(MoondanceContext);
    const [editableAccountList, setEditableAccountList] = useState([])
    const [edit_mode, set_edit_mode] = useState('edit')


    const [asset_is_linked, set_asset_is_linked] = useState(false);
    const [asset_is_saved, set_asset_is_saved] = useState(false);
    const [show_edit_asset, set_show_edit_asset] = useState(true);
    const [asset_is_duplicated, set_asset_is_duplicated] = useState(false);

    /*POSITION INITIAL VALUES ***********************************************************/
    const [position_id, set_position_id] = useState(0)
    const [position_commitment, set_position_commitment] = useState(1000)
    const [position_commitment_error, set_position_commitment_error] = useState('')
    const [position_shares, set_position_shares] = useState(0)
    const [position_nav_in_asset_currency, set_position_nav_in_asset_currency] = useState(0)

    const [position_subscription_date, set_position_subscription_date] = useState(new Date());
    const [position_account_id, set_position_account_id] = useState(0)
    const [position_account_error, set_position_account_error] = useState('')
    const [position_asset_id, set_position_asset_id] = useState('new')

    const [position_status, set_position_status] = useState(1)
    const [position_status_select, set_position_status_select] = useState([
        { id: 1, value: 1, label: 'Active' },
        { id: 2, value: 2, label: 'Upcoming' },
        { id: 4, value: 4, label: 'Closed' },
        { id: 100, value: 100, label: 'Deleted' }
    ])

    /*ASSET INITIAL VALUES ***********************************************************/
    const initialAssetState = {
        asset_id: 0,
        asset_currency: userCurrency,
        asset_name: '',
        asset_emitter: '',
        asset_type: 'others',
        asset_feeder_name: '',
        asset_feeder_type: '',
        asset_intermediate: '',
        asset_description: '',
        asset_inception_date: new Date(),
        asset_vintage_year: MoondanceLayout.currentYear(),
        asset_investment_period: 5,
        asset_detention_period: 5,
        asset_expected_drawdown: 100,
        asset_expected_irr: 9.86,
        asset_expected_tvpi: 1.60,
        asset_geographies: [{ id: 'Global', percentage: 100 }],
        asset_sectors: [{ id: 'diversified', percentage: 100 }],
        asset_model_yearly_capitalCall: [-20, -20, -20, -20, -20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_model_yearly_capitalcall_min: [-100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100],
        asset_model_yearly_capitalcall_max: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_real_yearly_capitalCall: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_sum_model_yearly_capitalcall: 0,
        asset_sum_real_yearly_capitalcall: 0,
        asset_sum_model_yearly_distribution: 0,
        asset_sum_real_yearly_distribution: 0,
        asset_model_yearly_distribution: [0, 0, 0, 0, 0, 32.2, 32.2, 32.2, 32.2, 32.2, 0, 0, 0, 0, 0, 0],
        asset_real_yearly_distribution: Array(15).fill(0),
        asset_model_yearly_distribution_min: Array(15).fill(0),
    };
    const [asset_id, set_asset_id] = useState(0);
    const [asset_status, set_asset_status] = useState(1);
    const [asset_name, set_asset_name] = useState('');
    const [asset_name_error, set_asset_name_error] = useState('');
    const [asset_emitter, set_asset_emitter] = useState('');
    const [asset_account_id, set_asset_account_id] = useState(0);
    //const [asset_type, set_asset_type] = useState("others");
    const [asset_description, set_asset_description] = useState('');
    const [asset_currency, set_asset_currency] = useState(userCurrency);
    const [asset_inception_date, set_asset_inception_date] = useState(new Date());
    const [asset_investment_period, set_asset_investment_period] = useState(5);
    const [asset_detention_period, set_asset_detention_period] = useState(5);
    const [asset_expected_drawdown, set_asset_expected_drawdown] = useState(100);
    const [asset_expected_irr, set_asset_expected_irr] = useState(9.86);
    const [asset_expected_tvpi, set_asset_expected_tvpi] = useState(1.60);
    const [asset_intermediate, set_asset_intermediate] = useState(0);
    const [asset_feeder_name, set_asset_feeder_name] = useState("");
    const [asset_feeder_type, set_asset_feeder_type] = useState("");
    const [asset_vintage_year, set_asset_vintage_year] = useState(MoondanceLayout.currentYear);
    const [asset_geographies, set_asset_geographies] = useState([{ id: 'Global', percentage: '100' }]);
    const [asset_types, set_asset_types] = useState([{ id: 'other', percentage: '100' }]);
    const [asset_sectors, set_asset_sectors] = useState([{ id: 'diversified', percentage: '100' }]);
    /*Model *********************************************************/
    const [asset_model_yearly_capitalcall, set_asset_model_yearly_capitalCall] = useState([-20, -20, -20, -20, -20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_model_yearly_capitalcall_min, set_asset_model_yearly_capitalcall_min] = useState([-100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100,]);
    const [asset_model_yearly_capitalcall_max, set_asset_model_yearly_capitalcall_max] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_real_yearly_capitalcall, set_asset_real_yearly_capitalCall] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_sum_model_yearly_capitalcall, set_asset_sum_model_yearly_capitalcall] = useState(0)
    const [asset_sum_real_yearly_capitalcall, set_asset_sum_real_yearly_capitalcall] = useState(0)
    const [asset_sum_model_yearly_distribution, set_asset_sum_model_yearly_distribution] = useState(0)
    const [asset_sum_real_yearly_distribution, set_asset_sum_real_yearly_distribution] = useState(0)
    const [asset_model_yearly_distribution, set_asset_model_yearly_distribution] = useState([0, 0, 0, 0, 0, 32.2, 32.2, 32.2, 32.2, 32.2, 0, 0, 0, 0, 0])
    const [asset_real_yearly_distribution, set_asset_real_yearly_distribution] = useState(Array(15).fill(0));
    const [asset_model_yearly_distribution_min, set_asset_model_yearly_distribution_min] = useState(Array(15).fill(0));

    const [assetTypeList, setAssetTypeList] = useState(MoondanceLayout.buildAssetTypeSelectOptions(MoondanceLayout.assetType));
    const [item, setItem] = useState([]);
    const [percentageOption] = useState(() => {
        const options = [];
        for (let i = 0; i <= 100; i += 5) {
            options.push({
                id: i,
                label: `${i}%`
            });
        }
        return options;
    });
    /* Geographies *********************************************************/
    const total_geography_percentage = (asset_geographies ? asset_geographies.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0);
    const handle_geography_change = (index, value) => {
        const newFormValues = [...asset_geographies];
        newFormValues[index].id = value;
        set_asset_geographies(newFormValues);

    }
    const handle_geography_add_row = () => {
        set_asset_geographies(prev => [...prev, { id: null, percentage: null }]);
    };
    const handle_geography_remove_row = (indexToRemove) => {
        set_asset_geographies(prev => prev.filter((_, index) => index !== indexToRemove));
    };
    const handle_geography_percentage_change = (index, value) => {
        const newFormValues = [...asset_geographies];
        newFormValues[index].percentage = value;
        set_asset_geographies(newFormValues);
    };
    const get_available_geography_percentage_options = (currentIndex) => {
        const currentRowPercentage = asset_geographies[currentIndex]?.percentage || 0;
        const otherRowsPercentage = asset_geographies.reduce((acc, curr, index) => {
            if (index !== currentIndex) {
                return acc + (curr.percentage || 0);
            }
            return acc;
        }, 0);

        return percentageOption.filter(option => option.id + otherRowsPercentage <= 100 || option.id === currentRowPercentage);
    };
    /***************************************************** Geographies END*/


    /* types *********************************************************/
    const total_type_percentage = (asset_types ? asset_types.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0);
    const handle_type_change = (index, value) => {
        console.log('handle_type_change', index, value)
        const newFormValues = [...asset_types];
        newFormValues[index].id = value;
        set_asset_types(newFormValues);

    }
    const handle_type_add_row = () => {
        set_asset_types(prev => [...prev, { id: null, percentage: null }]);
    };
    const handle_type_remove_row = (indexToRemove) => {
        set_asset_types(prev => prev.filter((_, index) => index !== indexToRemove));
    };
    const handle_type_percentage_change = (index, value) => {
        const newFormValues = [...asset_types];
        newFormValues[index].percentage = value;
        set_asset_types(newFormValues);
    };
    const get_available_type_percentage_options = (currentIndex) => {
        const currentRowPercentage = asset_types[currentIndex]?.percentage || 0;
        const otherRowsPercentage = asset_types.reduce((acc, curr, index) => {
            if (index !== currentIndex) {
                return acc + (curr.percentage || 0);
            }
            return acc;
        }, 0);

        return percentageOption.filter(option => option.id + otherRowsPercentage <= 100 || option.id === currentRowPercentage);
    };
    /***************************************************** Geographies END*/

    /* sectors *********************************************************/
    const total_sector_percentage = (asset_sectors ? asset_sectors.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0);
    const handle_sector_change = (index, value) => {
        const newFormValues = [...asset_sectors];
        newFormValues[index].id = value;
        set_asset_sectors(newFormValues);

    }
    const handle_sector_add_row = () => {
        if (asset_sectors && asset_sectors.length > 0) {
            set_asset_sectors(prev => [...prev, { id: null, percentage: null }]);
        } else {
            set_asset_sectors([{ id: null, percentage: 100 }]);
        }
    };
    const handle_sector_remove_row = (indexToRemove) => {
        set_asset_sectors(prev => prev.filter((_, index) => index !== indexToRemove));
    };
    const handle_sector_percentage_change = (index, value) => {
        const newFormValues = [...asset_sectors];
        newFormValues[index].percentage = value;

        //console.log('set_asset_sectors', newFormValues)
        set_asset_sectors(newFormValues);
    };
    const get_available_sector_percentage_options = (currentIndex) => {
        const currentRowPercentage = asset_sectors[currentIndex]?.percentage || 0;
        const otherRowsPercentage = asset_sectors.reduce((acc, curr, index) => {
            if (index !== currentIndex) {
                return acc + (curr.percentage || 0);
            }
            return acc;
        }, 0);

        return percentageOption.filter(option => option.id + otherRowsPercentage <= 100 || option.id === currentRowPercentage);
    };
    /***************************************************** Geographies END*/

    const add_one_year = () => {
        let new_asset_model_yearly_capitalCall = [...asset_model_yearly_capitalcall];
        let new_asset_model_yearly_distribution = [...asset_model_yearly_distribution];

        let new_asset_real_yearly_capitalcall = [...asset_real_yearly_capitalcall];
        let new_asset_real_yearly_distribution = [...asset_real_yearly_distribution];

        new_asset_model_yearly_capitalCall.push(0)
        new_asset_model_yearly_distribution.push(0)
        new_asset_real_yearly_capitalcall.push(0)
        new_asset_real_yearly_distribution.push(0)
        set_asset_model_yearly_capitalCall(new_asset_model_yearly_capitalCall)
        set_asset_model_yearly_distribution(new_asset_model_yearly_distribution)
        set_asset_real_yearly_capitalCall(new_asset_real_yearly_capitalcall)
        set_asset_real_yearly_distribution(new_asset_real_yearly_distribution)

    }
    /************************************************************************************************************************
     *  obtain the position details
    *************************************************************************************************************************/
    useEffect(() => {

        fetchCurrencyList().then(() => {
            if (props.editMode) {
                set_edit_mode(props.editMode)
                if (props.editMode === 'new') {
                    set_asset_id('new')
                    set_show_edit_asset(true)
                }
            }
            if (props && props.data && props.data.summary && props.data.summary.position_id !== position_id) {
                //console.log('props.data',props.data)
                set_position_id(props.data.summary.position_id)

                set_position_commitment(props.data.summary.position_commitment_in_asset_currency)
                set_position_shares(props.data.summary.position_initial_shares)
                set_position_subscription_date(props.data.summary.position_subscription_date_formatted)
                set_position_account_id(props.data.summary.position_account_id)
                set_position_asset_id(props.data.summary.position_asset_id)
                set_position_status(props.data.summary.position_status)


                set_asset_id(props.data.summary.asset_id)
                set_asset_currency(props.data.summary.asset_currency)
                set_asset_name(props.data.summary.asset_name)
                set_asset_emitter(props.data.summary.asset_emitter)
                //set_asset_type(props.data.summary.asset_type)
                set_asset_feeder_name(props.data.summary.asset_feeder_name)
                set_asset_feeder_type(props.data.summary.asset_feeder_type)
                set_asset_intermediate(props.data.summary.asset_intermediate)
                set_asset_description(props.data.summary.asset_description)
                set_asset_inception_date(props.data.summary.asset_inception_date)
                set_asset_vintage_year(props.data.summary.asset_vintage_year)
                set_asset_investment_period(props.data.summary.asset_investment_period)
                set_asset_detention_period(props.data.summary.asset_detention_period)
                set_asset_expected_drawdown(props.data.summary.asset_expected_drawdown * 100)
                set_asset_expected_irr(props.data.summary.asset_expected_irr * 100)
                set_asset_expected_tvpi(props.data.summary.asset_expected_tvpi)
                set_asset_geographies((props.data.geographies ? props.data.geographies : [{ id: 'global', percentage: '100' }]))
                set_asset_types((props.data.types ? props.data.types : [{ id: 'other', percentage: '100' }]))
                set_asset_sectors((props.data.sectors ? props.data.sectors : [{ id: 'diversified', percentage: '100' }]))
                set_position_nav_in_asset_currency(props.data.summary.position_nav_in_asset_currency)

                const updated_asset_real_yearly_capitalcall = [...asset_real_yearly_capitalcall];
                const updated_asset_model_yearly_capitalcall = [...asset_model_yearly_capitalcall];
                const updated_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min];

                const updated_asset_real_yearly_distribution = [...asset_real_yearly_distribution];
                const updated_asset_model_yearly_distribution = [...asset_model_yearly_distribution];
                const updated_asset_model_yearly_distribution_min = [...asset_model_yearly_distribution_min];

                // console.log('props.data.perYear', props.data.perYear)
                props.data.perYear.map((value, index) => {


                    //Is there already a model for this year

                    updated_asset_real_yearly_capitalcall[index] = 0
                    updated_asset_real_yearly_distribution[index] = 0
                    updated_asset_model_yearly_capitalcall[index] = 0
                    updated_asset_model_yearly_distribution[index] = 0

                    //PAST

                    if (value.year < parseInt(MoondanceLayout.currentYear())) {

                        //console.log('past', value.year,value)

                        // is there already a REAL distribution
                        if (value.sum_real_distribution_in_asset_currency > 0) {
                            updated_asset_real_yearly_distribution[index] = 100 * value.sum_real_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                        // is there already a real capital call for this year
                        if (value.sum_real_capital_call_in_asset_currency < 0) {
                            updated_asset_real_yearly_capitalcall[index] = 100 * value.sum_real_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                        updated_asset_model_yearly_distribution[index] = updated_asset_real_yearly_distribution[index];
                        updated_asset_model_yearly_capitalcall[index] = updated_asset_real_yearly_capitalcall[index];

                        //FUTURE
                    } else if (value.year > parseInt(MoondanceLayout.currentYear())) {


                        if (value.sum_model_capital_call_in_asset_currency < 0) {
                            updated_asset_model_yearly_capitalcall[index] = 100 * value.sum_model_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }

                        if (value.sum_model_distribution_in_asset_currency > 0) {
                            updated_asset_model_yearly_distribution[index] = 100 * value.sum_model_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                        //  console.log('future', value,   updated_asset_model_yearly_distribution[index])
                        //current year
                    } else {
                        // current year :  
                        if (value.sum_real_distribution_in_asset_currency > 0) {
                            //Set real distribution
                            updated_asset_real_yearly_distribution[index] = 100 * value.sum_real_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                            //Min distributionn cannot be < real distribution
                            updated_asset_model_yearly_distribution_min[index] = updated_asset_real_yearly_distribution[index];
                        }

                        if (value.sum_real_capital_call_in_asset_currency < 0) {
                            //set real capital call
                            updated_asset_real_yearly_capitalcall[index] = 100 * value.sum_real_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                            // Min call cannot be less than real  (negative value )
                            updated_asset_model_yearly_capitalcall_min[index] = updated_asset_real_yearly_capitalcall[index];
                        }

                        //model capital  call exceed real capital call
                        if (value.sum_model_capital_call_in_asset_currency < value.sum_real_capital_call_in_asset_currency) {

                            updated_asset_model_yearly_capitalcall[index] = 100 * value.sum_model_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        } else {
                            //Model capital call is at least = to real capital call
                            updated_asset_model_yearly_capitalcall[index] = updated_asset_real_yearly_capitalcall[index]
                        }

                        // model distribution exceed real distribution
                        if (value.sum_model_distribution_in_asset_currency > value.sum_real_distribution_in_asset_currency) {
                            updated_asset_model_yearly_distribution[index] = 100 * value.sum_model_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        } else {
                            //Model distribution is at least = to real distribution
                            updated_asset_model_yearly_distribution[index] = updated_asset_real_yearly_distribution[index];
                        }
                    }

                    return null; // You should return something from the map function
                });

                //console.log('props.data.perYear', updated_asset_model_yearly_distribution)

                set_asset_model_yearly_capitalCall(updated_asset_model_yearly_capitalcall);
                set_asset_real_yearly_capitalCall(updated_asset_real_yearly_capitalcall);

                set_asset_model_yearly_distribution(updated_asset_model_yearly_distribution);
                set_asset_real_yearly_distribution(updated_asset_real_yearly_distribution);
                set_asset_sum_real_yearly_capitalcall(updated_asset_real_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))
                set_asset_sum_model_yearly_capitalcall(updated_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))

                set_asset_sum_real_yearly_distribution(updated_asset_real_yearly_distribution.reduce((acc, curr) => acc + curr, 0))
                set_asset_sum_model_yearly_distribution(updated_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0))
            }
        })

    }, [props]);



    function handleSelectDate(selectedDates, dateStr, instance) {
        set_position_subscription_date(dateStr);
    }
    function handleSelectInceptionDate(selectedDates, dateStr, instance) {
        set_asset_inception_date(dateStr);
        set_asset_vintage_year(new Date(dateStr).getFullYear());

    }
    async function handleSaveForm() {
        console.log('handleSaveForm')
        console.log('position_id', position_id);

        let isError = false;
        //check if account is set
        if (asset_name === '') {
            set_asset_name_error('Please specify the asset name')
            isError = true
        }

        if (position_account_id === 0) {
            set_position_account_error('Please select an account')
            isError = true
        }

        if (position_commitment === 0) {
            set_position_commitment_error('Please specify the commitment to this position')
            isError = true
        }
        if (isError) {
            addAlert({
                status: 'danger',
                title: 'Missing data',
                message: 'Please check your form for error or missing informations'
            });
            return false
        }
        //Case 1 Existing position and  ASSET
        if (position_id && position_id !== 0) {
            // save the position
            // this is an update

            if (edit_mode === 'edit') {

                //This is an edit ansd the asset is edited as well
                if (editThisAsset) {
                    if (await saveAsset()) {
                        if (savePosition()) { set_asset_is_saved(true) }
                    }
                } else {
                    //This is an edit BUT the asset is NOT edited

                    if (savePosition()) { set_asset_is_saved(true) }
                }


            }
        } else if (asset_is_linked) {
            savePosition()
        } else if (!asset_is_linked) {
            await saveAsset()

        }

        Object.entries(matching_position_update).map(([index, item]) => {
            if (item.update) {

                console.log('Update matching position', item.asset_id, item.account_id, item)
                saveAsset(item.asset_id, item.account_id);
            }
        });


    }
    async function savePosition(new_position_id = position_id, new_asset_id = 0) {
        //console.log('savePosition', new_position_id)
        var position_post_data = {
            'position_account_id': position_account_id,
            'position_asset_id': (asset_id !== 'new' ? asset_id : new_asset_id),
            'position_subscription_date': MoondanceLayout.formatDate_DMY_to_YMD(position_subscription_date),
            'position_shares': parseFloat(position_shares),
            'position_status': parseInt(position_status),
            'position_commitment': position_commitment,
        };

        //console.log('savePosition', position_post_data)
        if (position_id && position_id !== 0 && position_id !== 'new') {
            var updateMode = true
            try {
                if (position_id) {
                    updateMode = true
                    position_post_data.position_id = position_id
                    var result = await moondanceApi.updatePosition(position_id, position_post_data)
                } else {
                    var result = await moondanceApi.addNewPosition(position_post_data)

                }
                var accountName = accountList.find(option => option.value === position_account_id)

                if (result.success) {
                    addAlert({
                        status: 'success',
                        title: 'CreatingPosition',
                        message: 'The position ' + asset_name + ' has  been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                    })

                    navigate('/position/' + result.id);

                    return true;
                } else {
                    addAlert({
                        status: 'danger',
                        title: 'Error',
                        message: 'The position ' + asset_name + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                    });
                    return false;
                }

            } catch (error) {
                addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                //console.log(error);
            }
        } else {
            var updateMode = false
            try {
                if (asset_id) {
                    var result = await moondanceApi.addNewPosition(position_post_data)

                    var accountName = accountList.find(option => option.value === position_account_id)

                    if (result.success) {
                        addAlert({
                            status: 'success',
                            title: 'Position ' + (updateMode ? 'updated ' : 'created '),
                            message: 'The position ' + asset_name + ' has  been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                        })
                        console.log('savePosition', result)
                        navigate('/position/' + result.id);
                        return true;
                    } else {
                        addAlert({
                            status: 'danger',
                            title: 'Error',
                            message: 'The position ' + asset_name + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                        });
                        return false;
                    }
                } else {
                    addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                }

            } catch (error) {
                addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                //console.log(error);
            }
        }
    }

    async function saveAsset(this_asset_id = asset_id, this_account_id = position_account_id) {

        var asset_post_data = {
            'asset_status': asset_status,
            'asset_name': asset_name,
            'asset_emitter': asset_emitter,
            'asset_account_id': this_account_id,
            'asset_currency': asset_currency,
            'asset_description': asset_description,
            'asset_inception_date': MoondanceLayout.formatDate_DMY_to_YMD(asset_inception_date),
            'asset_investment_period': asset_investment_period,
            'asset_detention_period': asset_detention_period,
            'asset_expected_drawdown': asset_expected_drawdown / 100,
            'asset_expected_irr': asset_expected_irr / 100,
            'asset_expected_tvpi': asset_expected_tvpi,
            'asset_intermediate': String(asset_intermediate),
            'asset_feeder_name': String(asset_feeder_name),
            'asset_feeder_type': String(asset_feeder_type),
            'asset_vintage_year': asset_vintage_year,
            'asset_geographies': asset_geographies,
            'asset_types': asset_types,
            'asset_sectors': asset_sectors,
            'asset_model_capitalcall': asset_model_yearly_capitalcall,
            'asset_model_distribution': asset_model_yearly_distribution
        };

        // console.log('saveAsset', asset_post_data)
        // console.log('saveAsset', props.editMode, asset_id, this_asset_id)

        var updateMode = false
        try {
            if (this_asset_id !== 'new' && this_asset_id !== 0) {

                updateMode = true
                asset_post_data.asset_id = this_asset_id

                //console.log('asset_post_data', asset_post_data)
                var result = await moondanceApi.updateAsset(this_asset_id, asset_post_data)
            } else {



                //NEW ASSET
                console.log('NEW ASSET', asset_post_data)
                var result = await moondanceApi.createAsset(asset_post_data)
                //console.log('saveAsset', result)
                //console.log('saveAsset: Result ID', result.id)
                set_asset_id(result.id)
                savePosition(0, result.id)
            }

            var accountName = accountList.find(option => option.value === position_account_id)

            if (result.success) {
                addAlert({
                    status: 'success',
                    title: 'Asset ' + (updateMode ? 'Update' : 'Created'),
                    message: 'The asset ' + asset_name + ' has been ' + (updateMode ? 'updated ' : 'created ') + (asset_post_data.asset_account_id ? 'in account ' + asset_post_data.asset_account_id
                        : '')
                });

                return true;
            } else {
                addAlert({
                    status: 'danger',
                    title: (result.error ? ' ' + result.error : ''),
                    message: 'The asset ' + (asset_name ? asset_name : '') + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (asset_post_data.asset_account_id ? 'in account ' + asset_post_data.asset_account_id : '')
                });
                return false;
            }
        } catch (error) {
            addAlert({
                status: 'danger',
                title: ((result && result.error) ? 'Undefined Error ' + result.error : ''),
                message: 'The asset ' + (asset_name ? asset_name : '') + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (asset_post_data.asset_account_id ? 'in account ' + asset_post_data.asset_account_id : '')
            });
            return false;
        }


    }

    function handle_currency_change(selectedOption) {
        set_asset_currency(selectedOption.id);
    }
    function setStateWithFallback(setter, newValue, initialStateValue) {
        setter(newValue !== undefined ? newValue : initialStateValue);
    }
    function setAssetState(newValues) {
        // console.log('setAssetState', newValues)
        // Call setStateWithFallback for each property you want to set
        setStateWithFallback(set_asset_id, newValues.asset_id, initialAssetState.asset_id);
        setStateWithFallback(set_asset_currency, newValues.asset_currency, initialAssetState.asset_currency);
        setStateWithFallback(set_asset_name, newValues.asset_name, initialAssetState.asset_name);
        setStateWithFallback(set_asset_emitter, newValues.asset_emitter, initialAssetState.asset_emitter);
        //setStateWithFallback(set_asset_type, newValues.asset_type, initialAssetState.asset_type);
        setStateWithFallback(set_asset_feeder_name, newValues.asset_feeder_name, initialAssetState.asset_feeder_name);
        setStateWithFallback(set_asset_feeder_type, newValues.asset_feeder_type, initialAssetState.asset_feeder_name);
        setStateWithFallback(set_asset_intermediate, newValues.asset_intermediate, initialAssetState.asset_intermediate);
        setStateWithFallback(set_asset_description, newValues.asset_description, initialAssetState.asset_description);
        setStateWithFallback(set_asset_inception_date, newValues.asset_inception_date, initialAssetState.asset_inception_date);
        setStateWithFallback(set_asset_vintage_year, newValues.asset_vintage_year, initialAssetState.asset_vintage_year);
        setStateWithFallback(set_asset_investment_period, newValues.asset_investment_period, initialAssetState.asset_investment_period);
        setStateWithFallback(set_asset_detention_period, newValues.asset_detention_period, initialAssetState.asset_detention_period);
        setStateWithFallback(set_asset_expected_drawdown, newValues.asset_expected_drawdown, initialAssetState.asset_expected_drawdown);
        setStateWithFallback(set_asset_expected_irr, newValues.asset_expected_irr, initialAssetState.asset_expected_irr);
        setStateWithFallback(set_asset_expected_tvpi, newValues.asset_expected_tvpi, initialAssetState.asset_expected_tvpi);
        setStateWithFallback(set_asset_geographies, newValues.asset_geographies, initialAssetState.asset_geographies);
        setStateWithFallback(set_asset_sectors, newValues.asset_sectors, initialAssetState.asset_sectors);
        setStateWithFallback(set_asset_model_yearly_capitalCall, newValues.asset_model_yearly_capitalCall, initialAssetState.asset_model_yearly_capitalCall);
        setStateWithFallback(set_asset_model_yearly_capitalcall_min, newValues.asset_model_yearly_capitalcall_min, initialAssetState.asset_model_yearly_capitalcall_min);
        setStateWithFallback(set_asset_model_yearly_capitalcall_max, newValues.asset_model_yearly_capitalcall_max, initialAssetState.asset_model_yearly_capitalcall_max);
        setStateWithFallback(set_asset_real_yearly_capitalCall, newValues.asset_real_yearly_capitalCall, initialAssetState.asset_real_yearly_capitalCall);
        setStateWithFallback(set_asset_sum_model_yearly_capitalcall, newValues.asset_sum_model_yearly_capitalcall, initialAssetState.asset_sum_model_yearly_capitalcall);
        setStateWithFallback(set_asset_sum_real_yearly_capitalcall, newValues.asset_sum_real_yearly_capitalcall, initialAssetState.asset_sum_real_yearly_capitalcall);
        setStateWithFallback(set_asset_sum_model_yearly_distribution, newValues.asset_sum_model_yearly_distribution, initialAssetState.asset_sum_model_yearly_distribution);
        setStateWithFallback(set_asset_sum_real_yearly_distribution, newValues.asset_sum_real_yearly_distribution, initialAssetState.asset_sum_real_yearly_distribution);
        setStateWithFallback(set_asset_model_yearly_distribution, newValues.asset_model_yearly_distribution, initialAssetState.asset_model_yearly_distribution);
        setStateWithFallback(set_asset_real_yearly_distribution, newValues.asset_sum_real_yearly_distribution, initialAssetState.asset_sum_real_yearly_distribution);
        setStateWithFallback(set_asset_model_yearly_distribution_min, newValues.asset_model_yearly_distribution_min, initialAssetState.asset_model_yearly_distribution_min);
    }
    function load_asset_data(id) {
        //console.log('load_asset_data')
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }

        try {
            moondanceApi.fetchPositionDetails(api_parameters, id).then(response => {

                console.log('load_asset_data', response)
                if (response.data) {
                    // moondanceApi.fetchPositionYearly(api_parameters, id).then(response => {
                    setAssetState(response.data)
                }
            })
        } catch (error) {
            //console.log(error);
        }
    }
    /* Capital calls & Distributions *****************************************************/
    function handleCapitalCallPerYear(key, event) {

        //fund the index of this
        const index = parseInt(key.split('-')[1]);
        const thisForm = key.split('-')[0];
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        const update_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min]
        const update_asset_model_yearly_capitalcall_max = [...asset_model_yearly_capitalcall_max]

        //console.log('index', thisForm)
        if (thisForm === 'capitalCallPerYear_form') {
            var value = event.target.value;
        } else {
            var value = event.target.value / 100;
        }
        if (value > 0) {
            value = -value * 100
        }
        //sum existing capital calls
        const sum_of_calls = -updatedCapitalCallPerYear.reduce((acc, curr) => acc + curr, 0) + updatedCapitalCallPerYear[index];

        update_asset_model_yearly_capitalcall_min[index] = (-asset_expected_drawdown + sum_of_calls < 0 ? -asset_expected_drawdown + sum_of_calls : 0)
        update_asset_model_yearly_capitalcall_max[index] = ((asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_capitalcall[index] < 0) ? asset_real_yearly_capitalcall[index] : 0)

        if (value > update_asset_model_yearly_capitalcall_max[index]) {
            value = update_asset_model_yearly_capitalcall_max[index];
            updatedCapitalCallPerYear[index] = update_asset_model_yearly_capitalcall_max[index];
        }
        if (value < update_asset_model_yearly_capitalcall_min[index]) {
            value = update_asset_model_yearly_capitalcall_min[index]
            updatedCapitalCallPerYear[index] = update_asset_model_yearly_capitalcall_min[index]
        } else {
            updatedCapitalCallPerYear[index] = parseFloat(value)
        }
        set_asset_sum_model_yearly_capitalcall(updatedCapitalCallPerYear.reduce((acc, curr) => acc + curr, 0))
        set_asset_model_yearly_capitalcall_min(update_asset_model_yearly_capitalcall_min)
        set_asset_model_yearly_capitalcall_max(update_asset_model_yearly_capitalcall_max)
        set_asset_model_yearly_capitalCall(updatedCapitalCallPerYear);



        if (props.editMode === 'New') {
            recalculateDistributions()
        }
    }
    function recalculateDistributions() {

        //console.log('recalculateDistributions')    

        // Calculate updatedDistributionPerYear based on updatedCapitalCallPerYear
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        let updatedDistributionPerYear = [...asset_model_yearly_distribution]
        let actualDistributionPerYear = [...asset_model_yearly_distribution]
        for (let i = 0; i < updatedDistributionPerYear.length; i++) {
            updatedDistributionPerYear[i] = 0
        }
        for (let i = 0; i < updatedDistributionPerYear.length; i++) {
            var capitalCall = updatedCapitalCallPerYear[i];
            var updatedIndex = parseInt(i) + parseInt(asset_detention_period);


            if (i < parseInt(asset_detention_period)) {
                if (asset_vintage_year + i <= MoondanceLayout.currentYear() && asset_real_yearly_distribution[i] > 0) {
                    updatedDistributionPerYear[i] = asset_real_yearly_distribution[i]
                } else {
                    updatedDistributionPerYear[i] = 0
                }


            }
            if (updatedIndex < 15) {
                if (asset_vintage_year + updatedIndex > MoondanceLayout.currentYear()) {
                    updatedDistributionPerYear[updatedIndex] = Math.round(
                        -capitalCall * asset_expected_tvpi
                    );


                    /*!! current year recalculation cannot be lower than actual value  */
                } else if (asset_vintage_year + updatedIndex === MoondanceLayout.currentYear()) {
                    updatedDistributionPerYear[updatedIndex] = Math.min(
                        asset_real_yearly_distribution[updatedIndex],
                        Math.round(-capitalCall * asset_expected_tvpi)
                    );
                } else {
                    updatedDistributionPerYear[updatedIndex] = asset_model_yearly_distribution[updatedIndex]
                }
            }
        }
        set_asset_model_yearly_distribution(updatedDistributionPerYear)
        set_asset_sum_model_yearly_distribution(updatedDistributionPerYear.reduce((acc, curr) => acc + curr, 0))

    }
    function handleDistributionPerYear(key, event) {
        //console.log('handleDistributionPerYear', event.target.value)
        //fund the index of this
        const index = parseInt(key.split('-')[1]);
        var value = event.target.value / 100;

        if (key.split('-')[0] === 'distributionPerYear_form') {
            var value = event.target.value * 100
        } else {
            var value = event.target.value
        }
        const updated_asset_model_yearly_distribution = [...asset_model_yearly_distribution];
        const updateddistributionPerYear_min = [...asset_model_yearly_distribution_min];

        //if current year
        //console.log('asset_real_yearly_distribution', asset_real_yearly_distribution[index])
        updateddistributionPerYear_min[index] = 0; //((asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_distribution[index] > 0) ? asset_real_yearly_distribution[index] : 0)

        //console.log('MIN', updateddistributionPerYear_min[index])
        if (value < updateddistributionPerYear_min[index]) {
            value = updateddistributionPerYear_min[index] * 100
        }

        updated_asset_model_yearly_distribution[index] = parseFloat(value / 100)
        //console.log('asset_sum_real_yearly_distribution', asset_sum_real_yearly_distribution)

        set_asset_sum_model_yearly_distribution(updated_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0))
        set_asset_model_yearly_distribution(updated_asset_model_yearly_distribution);
        set_asset_model_yearly_distribution_min(updateddistributionPerYear_min);
    }
    async function fetch_all_position_list() {
        //console.log('fetch_all_position_list', accountList)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        //console.log('api_parameters', api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)

            if (response.data.length > 0) {
                set_all_positions_list(response.data)
                //console.log('fetch_all_position_list', response.data)
                return response.data

            } else {
                return []
            }

        } catch (error) {
            console.log(error);
            return []
        }


        return []

    }
    async function find_matching_position() {
        const all_positions_list = await fetch_all_position_list();
        let matchingPositions = all_positions_list.filter(position => {
            return (position.asset_name === asset_name || (position.asset_feeder_name !== 'undefined' && position.asset_feeder_name !== '' && position.asset_feeder_name === asset_feeder_name))
                && position.position_id !== position_id;
        });
        console.log('matching positions', matchingPositions)

        set_matching_position_list(matchingPositions)


        if (matchingPositions && matchingPositions.length > 0) {
            let this_matching_position_update = []

            matchingPositions.map((item, index) => {
                this_matching_position_update.push({
                    id: item.position_id,
                    asset_id: item.asset_id,
                    name: item.asset_name,
                    commitment: item.position_commited_in_asset_currency,
                    account_id: item.position_account_id,
                    account_name: item.position_account_name,
                    update: (matching_position_update[index] ? matching_position_update[index] : false)
                })
            })
            set_matching_position_update(this_matching_position_update)
            return this_matching_position_update;
        }
    }
    async function fetchCurrencyList() {
        try {
            var date = MoondanceLayout.formatDate(new Date(), 'yyyy-mm-dd')
            var data = await moondanceApi.getLastExchangeRate(date);
            ////console.log('fetchCurrencyList', data)
            setCurrencyList(buildCurrencyList(data.data))

        } catch (error) {
            //console.log(error);
        }
    }
    function buildAssetArray(assetArray) {
        ////console.log('currency list', currencyList)
        ////console.log('asset_array', assetArray)
        const options = assetArray
            .filter(
                (item) =>
                    item.asset_account_id !== position_account_id
                    || ((props && props.data && props.data.summary && props.data.summary.position_asset_id) ? item.asset_id === props.data.summary.position_asset_id : null)
                    || item.asset_id === position_asset_id)
            .map((item) => ({
                id: item.asset_id,
                value: item.asset_id,
                position_id: item.position_id,
                label: `${item.asset_name} (${item.asset_account_name} - ${item.asset_status_label}) `,
            }));
        // Add the first option
        options.unshift({ id: 'new', position_id: 0, value: 'new', label: 'Create a new asset' })

        ////console.log('options', options)
        return options;
    }
    function handle_tvpi_change(newTvpi) {
        let newIrr = Math.round(1000 * ((newTvpi ** (1 / asset_detention_period)) - 1) * 100) / 1000
        set_asset_expected_irr(newIrr)
        set_asset_expected_tvpi(newTvpi)

        if (props.editMode === 'New') {
            recalculateDistributions()
        }
    }
    function handle_detention_period_change(newDetentionPeriod) {

        if (newDetentionPeriod < 0) {
            newDetentionPeriod = 0
        }
        set_asset_expected_tvpi(Math.round(1000 * (1 + (asset_expected_irr / 100)) ** newDetentionPeriod) / 1000)
        set_asset_detention_period(newDetentionPeriod)

        //asset_model_yearly_capitalCall
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        for (let i = 0; i < updatedCapitalCallPerYear.length; i++) {
            if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                if (i < asset_investment_period) {
                    updatedCapitalCallPerYear[i] = Math.round(-asset_expected_drawdown * 100 / asset_investment_period) / 100

                } else {
                    updatedCapitalCallPerYear[i] = 0
                }
            }

        }
        //console.log('updatedCapitalCallPerYear',asset_vintage_year +i, i, asset_expected_drawdown,updatedCapitalCallPerYear[i])
        set_asset_model_yearly_capitalCall(updatedCapitalCallPerYear);


    }
    function handle_investment_period_change(newInvestmentPeriod) {
        if (newInvestmentPeriod < 0) {
            newInvestmentPeriod = 0
        }
        set_asset_investment_period(newInvestmentPeriod)
    }

    useEffect(() => {
        if (props.editMode === 'New') {
            recalculateDistributions()
        }
    }, [asset_model_yearly_capitalcall, asset_expected_drawdown, asset_expected_drawdown, asset_investment_period, asset_detention_period],)


    const fetchSelectedPositionDetails = async (id) => {
        var api_parameters = {
            account: buildAccountParameter(accountList),
            userCurrency: userCurrency,
        }
        try {
            const thisFund = await moondanceApi.fetchPositionDetails(api_parameters, id);
            return thisFund.data;

        } catch (error) {
            return false;
        }
    };
    function handleCapitalCallMatchDrawdown(mode) {

        let current_asset_model_yearly_capitalcall = [...asset_model_yearly_capitalcall]

        const update_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min]
        const update_asset_model_yearly_capitalcall_max = [...asset_model_yearly_capitalcall_max]



        if (mode == 'calls') {
            let delta = asset_expected_drawdown - (-asset_sum_model_yearly_capitalcall)
            let rem_asset_expected_drawdown = (parseFloat(asset_expected_drawdown) + parseFloat(asset_sum_real_yearly_capitalcall))
            let rem_model_yealry_capitalcall =asset_sum_real_yearly_capitalcall- asset_sum_model_yearly_capitalcall
            console.log('Real call',asset_sum_real_yearly_capitalcall)
            console.log('Target Exp Remaining',rem_asset_expected_drawdown)
            console.log('Model Exp Remaining',asset_sum_real_yearly_capitalcall- asset_sum_model_yearly_capitalcall)
            console.log('Delta target - model',delta)
           


            //Multiply each future call by ratio
            //console.log('handleCapitalCallMatchDrawdown',asset_model_yearly_capitalcall,asset_vintage_year,MoondanceLayout.currentYear())
            for (let i = 0; i < current_asset_model_yearly_capitalcall.length; i++) {
                if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                    let this_ratio = current_asset_model_yearly_capitalcall[i] / rem_model_yealry_capitalcall
                    current_asset_model_yearly_capitalcall[i] = current_asset_model_yearly_capitalcall[i] + (delta * this_ratio)
                }
            }
            set_asset_sum_model_yearly_capitalcall(current_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))
            set_asset_model_yearly_capitalCall(current_asset_model_yearly_capitalcall);
            recalculateDistributions()
        } else {
            let ratio = asset_expected_drawdown / -current_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0)
            let new_drawdown = asset_expected_drawdown / ratio
            set_asset_expected_drawdown(new_drawdown)
        }

    }
    function handleDistributionsMatchTvpi(mode) {
        if (mode == 'TVPI') {

            let ratio = (asset_sum_model_yearly_distribution) / (asset_expected_tvpi * asset_expected_drawdown / 100)
            //console.log('handleDistributionsMatchTvpi',ratio,asset_sum_real_yearly_distribution,asset_sum_model_yearly_distribution)
            let newTvpi = Math.round(asset_expected_tvpi * ratio * 1000) / 100000
            set_asset_expected_tvpi(newTvpi)
            set_asset_expected_irr(Math.round(1000 * ((newTvpi ** (1 / asset_detention_period)) - 1) * 100) / 1000)

        } else if (mode == 'nav') {

            let ratio = (position_nav_in_asset_currency) / ((asset_sum_model_yearly_distribution - asset_sum_real_yearly_distribution) * position_commitment / 100)

            let current_asset_model_yearly_distribution = [...asset_model_yearly_distribution]

            for (let i = 0; i < current_asset_model_yearly_distribution.length; i++) {
                if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                    current_asset_model_yearly_distribution[i] = Math.round(current_asset_model_yearly_distribution[i] * ratio * 100) / 100
                }
            }
            set_asset_model_yearly_distribution(current_asset_model_yearly_distribution)
            set_asset_sum_model_yearly_distribution(current_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0))

        } else {

            let current_asset_model_yearly_distribution = [...asset_model_yearly_distribution]

            let model_expected_total_distrib = parseFloat(asset_expected_tvpi * asset_expected_drawdown)
            let model_already_distributed = parseFloat(asset_sum_real_yearly_distribution)
            let model_to_be_distributed = asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0) - model_already_distributed
            let delta = model_expected_total_distrib - model_already_distributed - model_to_be_distributed


            for (let i = 0; i < current_asset_model_yearly_distribution.length; i++) {
                if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                    let pct_of_future_distrib = current_asset_model_yearly_distribution[i] / model_to_be_distributed
                    let new_distrib = current_asset_model_yearly_distribution[i] + (pct_of_future_distrib * delta)
                    current_asset_model_yearly_distribution[i] = Math.round(new_distrib * 100) / 100
                    //console.log(asset_vintage_year + i, 'now:',current_asset_model_yearly_distribution[i],'pct:', pct_of_future_distrib, 'to be added:',new_distrib, pct_of_future_distrib * delta, 'New value:',current_asset_model_yearly_distribution[i] + (pct_of_future_distrib * delta))
                }
            }
            set_asset_model_yearly_distribution(current_asset_model_yearly_distribution)
            set_asset_sum_model_yearly_distribution(current_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0))

        }
    }
    useEffect(() => {
        var result = buildAssetArray(assetList);
        setAssetSelectList(result)
    }, [position_account_id])

    useEffect(() => {
        set_asset_sum_model_yearly_capitalcall(asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))

    }, [asset_model_yearly_capitalcall])
    useEffect(() => {
        console.log('look for matching position')
        if (position_id && position_id !== 0) {
            let matchingPosition = find_matching_position()
        }
    }, [position_id, asset_name, asset_feeder_name])
    ///Fetch Asset list after position_id change 
    useEffect(() => {
        //console.log('accountList', accountList, accountList.length)
        if (accountList.length > 1) {
            let filteredAccountList = accountList.filter(item => item.user_can_edit_position === 1)
            setEditableAccountList(filteredAccountList)


        } else if (accountList.length < 2) {
            console.log('set account as default')
            let filteredAccountList = accountList.filter(item => item.user_can_edit_position === 1)
            setEditableAccountList(filteredAccountList)
            set_position_account_id(accountList[0].value)
        }

        async function fetchData() {
            var api_parameters = {
                account: buildAccountParameter(accountList),
                status: 'All',
                userCurrency: userCurrency,
            }

            if (accountList) {
                try {
                    moondanceApi.fetchAssetsList(api_parameters).then(response => {

                        if (response.data && response.data.length > 0) {
                            setAssetList(response.data)
                            var result = buildAssetArray(response.data);
                            setAssetSelectList(result)

                        }
                    })
                } catch (error) {
                    //console.log(error);
                }
            }
        }

        fetchData();


    }, [accountList, props.data]);


    function handleChangeSimilarPositionCheck(id) {
        console.log('updatedMatchingPositionUpdate', id)

        const updatedMatchingPositionUpdate = matching_position_update.map(item => {
            if (item.id === id) {
                return { ...item, update: !item.update };
            }
            return item;
        });
        console.log('updatedMatchingPositionUpdate', updatedMatchingPositionUpdate)
        set_matching_position_update(updatedMatchingPositionUpdate);
    }

    function checkEssential() {
        if (asset_name && position_account_id && asset_currency && position_subscription_date && asset_inception_date) {
            return true;
        } else {
            return false;
        }

    }

    function fetchUserProfile() {
        console.log('fetchUserProfile', props)
        moondanceApi.getLoggedinUser().then(profile => {
            if (profile) {
                setUserData(profile.data);

            }


        }).catch(errors => {
            console.log(errors)
        });
    }
    useEffect(() => {
        fetchUserProfile();
    }, [props]);


    /*  Return function **************************************************************************/




    return (
        <React.Fragment>

            {(user_data.current_positions_count >= user_data.max_positions_allowed && props.editMode === 'new') ?

                <UncontrolledAlert color="light" role="alert" className="card border mt-4 mt-lg-0 p-0 mb-0">

                    <div className="card-header bg-soft-danger">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h5 className="font-size-16 text-danger my-1">
                                    Maximum number of allowed positions reached
                                </h5>
                            </div>
                            <div className="flex-shrink-0">

                            </div>
                        </div>
                    </div>
                    <CardBody>
                        <div className="text-center">

                            <p className="alert-heading">

                                You have reached the maximum number of positions you can create. Please <Link to="/contact" > contact us</Link> to upgrade your subscription plan to create more positions.
                            </p>
                        </div>
                    </CardBody>
                </UncontrolledAlert>



                :
                <Row>

                    <Card>
                        <CardHeader className="bg-transparent border-bottom  card-header ">
                            <Row>
                                <Col xl={11} sm={12} md={12} xs={12} style={{ marginTop: "1em" }}>
                                    <h5 >Essential informations     {(checkEssential() ? <span className="badge bg-primary me-1">Complete</span> : (!show_essentials ? <Button onClick={() => { set_show_essentials(!show_essentials) }} className="btn btn-sm btn-danger"><b><span className="mdi mdi-alert-outline"></span> Missing Required Informations!</b></Button> : ''))}</h5>
                                    {props.editMode !== 'edit' ? <p >Let's begin by adding the essential informations about the new positon we want to create.</p> : ''}

                                </Col>
                                <Col xl={1} sm={12} md={12} xs={12} className="d-flex  align-items-center justify-content-end " >
                                    <Button
                                        onClick={() => { set_show_essentials(!show_essentials) }}
                                        className="btn btn-link">{(!show_essentials ? <span><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Expand</span> : <span><span className="mdi mdi-arrow-up-drop-circle-outline"></span> Collapse</span>)}</Button>
                                </Col>
                            </Row>
                        </CardHeader>

                        {(show_essentials ?

                            <>
                                <CardBody>
                                    <Row>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column >Asset Name {(!asset_name || asset_name.length === 0 ? <span className="badge bg-danger me-1">Required</span> : '')}</Form.Label>
                                            <InputGroup >
                                                <input
                                                    className="form-control"
                                                    type="text"

                                                    value={
                                                        asset_name
                                                            ? asset_name
                                                            : ''
                                                    }

                                                    onChange={(e) => { set_asset_name(e.target.value); }}
                                                    placeholder="Enter the asset name"
                                                />
                                            </InputGroup>
                                            {(asset_name_error ? <p style={{ color: 'red' }}>{asset_name_error}</p> : '')}




                                        </Form.Group>
                                        <p className="card-title-desc"> To enhance efficiency, it's advisable to assign the same name to identical asset positions across your various accounts.<br />This uniform naming convention enables you to simultaneously update the net asset value (NAV) and record transactions for those positions, streamlining your financial management process.</p>

                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm="6">Account {(!position_account_id || position_account_id === 0 ? <span className="badge bg-danger me-1">Required</span> : '')}</Form.Label>

                                            <Select

                                                onChange={(e) => { set_position_account_id(e.value); }}

                                                value={(position_account_id ? accountList.find(option => option.value === position_account_id) : null)}
                                                placeholder="Select Account"
                                                formatOptionLabel={formatOptionLabel}

                                                styles={MoondanceLayout.select2customStyles}

                                                options={editableAccountList}
                                                classNamePrefix="select2-selection"
                                            />
                                            {(position_account_error ? <p style={{ color: 'red' }}>{position_account_error}</p> : '')}


                                        </Form.Group>
                                        <p className="card-title-desc">  You have the flexibility to set up multiple accounts to manage various types of positions or to separate holdings belonging to different owners or entities. To create additional accounts, simply navigate to the profile section.<br />However, please be aware that the total number of accounts you're allowed to create may be restricted by your current subscription plan.</p>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm="2">Asset Currency {asset_currency}</Form.Label>
                                            <Select
                                                onChange={(e) => { handle_currency_change(e) }}
                                                formatOptionLabel={formatOptionLabel}

                                                styles={MoondanceLayout.select2customStyles}
                                                value={currencyList.find(option => option.id === asset_currency)}
                                                options={currencyList}
                                            />


                                        </Form.Group>
                                        <p className="card-title-desc"> For consistency, all net asset values (NAVs) and transactions associated with these positions must be entered in the same currency.<br />However, they will be displayed on your dashboard in your preferred reference currency, converted at their historical exchange rates. This approach ensures a uniform view of your financial information, facilitating easier analysis and management.</p>
                                        <Row>
                                            <Col md={4} sm={12}>
                                                <Form.Group as={Row} className="mb-3" >

                                                    <Form.Label  >Subscription date</Form.Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            allowInput={true}
                                                            className="form-control "
                                                            onChange={handleSelectDate}
                                                            value={position_subscription_date}
                                                            options={{
                                                                clickOpens: false,  // ensure this is true
                                                                allowInput: true,   // ensure direct input is allowed
                                                                dateFormat: "d/m/Y",
                                                            }}
                                                            ref={flatpickrRef}

                                                        />
                                                        <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                                                            <span className="input-group-text">
                                                                <i className="mdi mdi-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </InputGroup>

                                                </Form.Group>

                                            </Col>
                                            <Col md={4} sm={12}>

                                                <Form.Label>Inception date</Form.Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        allowInput={true}
                                                        className="form-control "
                                                        onChange={handleSelectInceptionDate}
                                                        value={asset_inception_date}
                                                        options={{
                                                            clickOpens: false,  // ensure this is true
                                                            allowInput: true,   // ensure direct input is allowed
                                                            dateFormat: "d/m/Y",
                                                        }}
                                                        ref={flatpickrRef2}

                                                    />
                                                    <div className="input-group-append" onClick={() => flatpickrRef2.current.flatpickr.open()}>
                                                        <span className="input-group-text">
                                                            <i className="mdi mdi-calendar"></i>
                                                        </span>
                                                    </div>
                                                </InputGroup>

                                            </Col>
                                            <Col md={4} sm={12}>
                                                <Form.Label >Vintage (first Year)</Form.Label>
                                                <InputGroup>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            asset_vintage_year
                                                                ? asset_vintage_year
                                                                : ''
                                                        }

                                                        onChange={(e) => { set_asset_vintage_year(parseInt(e.target.value)); }}
                                                        placeholder="Enter the asset vintage year"
                                                    />
                                                </InputGroup>

                                            </Col>

                                        </Row>



                                        <p className="card-title-desc"> The "Vintage Date" is essential for organizing this asset in reports and evaluating its performance metrics, highlighting the year the investment was made or the capital was committed.<br />Conversely, the "Subscription Date" and "inception date" are used exclusively for maintaining historical records.</p>


                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm="2">Commitment</Form.Label>


                                            <InputGroup>
                                                <input
                                                    className="form-control"
                                                    type="number"

                                                    value={
                                                        position_commitment
                                                            ? position_commitment
                                                            : 1000}
                                                    onChange={(e) => { set_position_commitment(parseInt(e.target.value)); }}
                                                    placeholder="Commitment"
                                                />
                                                <div className="input-group-append" >
                                                    <span className="input-group-text">
                                                        {asset_currency}
                                                    </span>
                                                </div>


                                            </InputGroup>
                                            {(position_commitment_error ? <p style={{ color: 'red' }}>{position_commitment_error}</p> : '')}


                                        </Form.Group>
                                        <p className="card-title-desc"> Input the total amount of capital committed to this investment. Be aware that the fund may not call upon the entire sum of pledged capital, and management fees could result in the total cost exceeding this initial commitment.</p>


                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm="2">Position Status</Form.Label>
                                            <Select
                                                onChange={(e) => { set_position_status(e.value); }}
                                                value={(position_status ? position_status_select.find(option => option.value === position_status) : null)}
                                                placeholder="Select position Status"
                                                formatOptionLabel={formatOptionLabel}

                                                styles={MoondanceLayout.select2customStyles}

                                                options={position_status_select}
                                                classNamePrefix="select2-selection"
                                            />
                                        </Form.Group>
                                        <p className="card-title-desc">
                                            By default, your reports will display only active positions. If you wish to change which statuses are visible in your reports, including keeping certain positions hidden, you can do so by accessing the "Select Status" page. From there, simply choose the desired statuses to adjust your report visibility.</p>

                                    </Row>

                                    {(matching_position_update && Object.keys(matching_position_update).length > 0) ?
                                        <Row><hr />
                                            <h5>Similar Positions in other accounts</h5>
                                            <p>There are similar positions available in different accounts to which you have access.
                                                You can update these positions data and model by checking the corresponding checkboxes.<br />
                                                Account, commitement, number of shares, subscription date and status will not be copied.</p>


                                            <table>
                                                {Object.entries(matching_position_update).map(([index, item]) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <input
                                                                onClick={() => (handleChangeSimilarPositionCheck(item.id))}
                                                                type="checkbox"
                                                                checked={item.update}
                                                            >
                                                            </input></td>
                                                        <td>#{item.id} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>


                                                    </tr>
                                                ))}
                                            </table>
                                        </Row>
                                        : ''}

                                </CardBody>
                                <CardFooter className="bg-transparent border-top  card-header ">
                                    < Form.Group as={Row} className=" mb-3d"  >

                                        <Col sm={12} md={9}>
                                            {props.editMode === 'New' ? <p><b>You can save this position now and return later to add more details. Initially, some fields will remain blank, and the capital call and distributions model will be set to their default values. If you prefer, you can complete this information immediately by clicking  "<Link onClick={() => { set_show_essentials(false); set_show_details(true) }}>Add Details</Link>" or "<Link onClick={() => { set_show_essentials(false); set_show_model(true) }}>Edit Model</Link>".</b></p> : ''}

                                        </Col>
                                        <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
                                            <button style={{ 'marginRight': '0.5em' }} className="btn btn-outline-primary" onClick={() => { set_show_essentials(false); set_show_details(true) }}><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Add Details</button>
                                            <button style={{ 'marginRight': '0.5em' }} className="btn btn-outline-primary" onClick={() => { set_show_essentials(false); set_show_model(true) }}><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Edit Model</button>
                                            <button className="btn btn-danger" onClick={() => { handleSaveForm() }}><span className="mdi mdi-record"></span> Save position</button>
                                        </Col>

                                    </Form.Group>
                                </CardFooter>
                            </>
                            : '')}
                    </Card>

                    <Card>
                        <CardHeader className="bg-transparent border-bottom  card-header ">
                            <Row>
                                <Col xl={11} sm={12} md={12} xs={12}>
                                    <h5 >Additional informations</h5>
                                    <p >You have the option to add additional information describing this position. Doing so will aid in accurately categorizing this position within various reports.</p>

                                </Col>
                                <Col xl={1} sm={12} md={12} xs={12} className="d-flex flex-row-reverse " >
                                    <Button
                                        onClick={() => { set_show_details(!show_details) }}
                                        className="btn btn-link">{(!show_details ? <span><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Expand</span> : <span><span className="mdi mdi-arrow-up-drop-circle-outline"></span> Collapse</span>)}</Button>

                                </Col>
                            </Row>
                        </CardHeader>
                        {(show_details ?
                            <>
                                <CardBody>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label>Shares</Form.Label>
                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="number"

                                                value={position_shares ?
                                                    position_shares
                                                    : 0}
                                                onChange={(e) => { set_position_shares(e.target.value); }}
                                                placeholder="Shares"
                                            />


                                        </InputGroup>

                                    </Form.Group>
                                    <p className="card-title-desc">

                                        Depending on the asset's structure, shares can be issued upon subscription with their value adjusted after each transaction. In this scenario, enter the number of shares subscribed.
                                        <br />Alternatively, other structures may involve adding or repurchasing shares with each capital call or distribution. In such cases, set the initial share count to 0 and specify the number of shares added or reduced with each transaction</p>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label>General Partner</Form.Label>
                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="text"

                                                value={
                                                    asset_emitter
                                                        ? asset_emitter
                                                        : ''
                                                }

                                                onChange={(e) => { set_asset_emitter(e.target.value); }}
                                                placeholder="Enter the asset emitter (issuer)"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <p className="card-title-desc"> You may record the name of the general partner issuing this position for documentation and reporting purposes.</p>


                                    <p className="card-title-desc"> You may record the type of position for documentation and reporting purposes.</p>

                                    <Form.Group as={Row} className="mb-3" >

                                        <Form.Label >Feeder name</Form.Label>

                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={
                                                    asset_feeder_name
                                                        ? asset_feeder_name
                                                        : ''
                                                }

                                                onChange={(e) => { set_asset_feeder_name(e.target.value); }}
                                                placeholder="Enter the asset feeder name"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <p className="card-title-desc"> You have the option to document the name of the feeder structure holding this position for both documentation and reporting purposes.</p>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label >Legal Structure</Form.Label>
                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="text"

                                                v value={
                                                    asset_feeder_type
                                                        ? asset_feeder_type
                                                        : ''
                                                }

                                                onChange={(e) => { set_asset_feeder_type(e.target.value); }}
                                                placeholder="Enter the asset feeder type"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <p className="card-title-desc"> You have the option to document the type of feeder structure holding this position for both documentation and reporting purposes.</p>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label>Intermediate</Form.Label>

                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="text"

                                                value={
                                                    asset_intermediate
                                                        ? asset_intermediate
                                                        : ''
                                                }

                                                onChange={(e) => { set_asset_intermediate(e.target.value); }}
                                                placeholder="Enter the asset intermediate"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <p className="card-title-desc"> You can specify the intermediary (e.g., bank, family office, or other) through which you subscribed to this position for documentation and reporting purposes</p>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label >Asset Description</Form.Label>

                                        <InputGroup>
                                            <textarea
                                                value={(asset_description ? asset_description : '')}
                                                rows={5}
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => { set_asset_description(e.target.value); }}

                                                placeholder="Enter the asset description"
                                            />


                                        </InputGroup>

                                    </Form.Group>
                                    <p className="card-title-desc"> You can provide a detailed description of this position for documentation and reference purposes</p>


                                    <Row>
                                        <Col lg="6" md="6">
                                            <label>Type </label>
                                        </Col>
                                        <Col lg="3" md="3">
                                            <label>% of this type</label>
                                        </Col>

                                    </Row>
                                    {(asset_types ?
                                        asset_types.map((row, index) => {

                                            return (
                                                <Row key={'type_' + index} style={{ marginBottom: "1em" }}>
                                                    <Col lg="6" md="6">
                                                        <Select
                                                            value={(row.id ? assetTypeList.find(option => option.value === row.id) : null)}
                                                            placeholder="Select Type (optional)"
                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={assetTypeList}
                                                            onChange={(selected) => handle_type_change(index, selected?.value)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" md="3">
                                                        <Select
                                                            value={percentageOption.find(option => option.id === parseInt(row.percentage))}
                                                            placeholder="Select percentage"

                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={get_available_type_percentage_options(index)}

                                                            onChange={(selected) => handle_type_percentage_change(index, selected?.id)}
                                                        />
                                                    </Col>
                                                    <Col lg="1">
                                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => handle_type_remove_row(index)}>Remove</button>
                                                    </Col>
                                                </Row>)
                                        })
                                        : '')}

                                    {total_type_percentage < 100 && (
                                        <button style={{ 'marginBottom': '1em' }} className="btn btn-outline-secondary btn-sm" onClick={handle_type_add_row}>Add Type</button>
                                    )}

                                    <p className="card-title-desc"> You have the option to identify the type of fund associated with this position for reporting and documentation purposes. You can allocate an estimated percentage of assets linked to this position across different types. Should the total type allocation sum to less than 100%, the remainder will automatically be attributed to the 'Other' category. </p>
                                    <hr />
                                    <Row>
                                        <Col lg="6" md="6">
                                            <label>Geography </label>
                                        </Col>
                                        <Col lg="3" md="3">
                                            <label>% in this Geography</label>
                                        </Col>

                                    </Row>
                                    {(asset_geographies ?
                                        asset_geographies.map((row, index) => {

                                            return (
                                                <Row key={'geography_' + index} style={{ marginBottom: "1em" }}>
                                                    <Col lg="6" md="6">
                                                        <Select
                                                            value={MoondanceLayout.geographyList.find(option => option.id === row.id)}
                                                            placeholder="Select geography (optional)"
                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={MoondanceLayout.geographyList}
                                                            onChange={(selected) => handle_geography_change(index, selected?.id)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" md="3">
                                                        <Select
                                                            value={percentageOption.find(option => option.id === parseInt(row.percentage))}
                                                            placeholder="Select percentage"

                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={get_available_geography_percentage_options(index)}

                                                            onChange={(selected) => handle_geography_percentage_change(index, selected?.id)}
                                                        />
                                                    </Col>
                                                    <Col lg="1">
                                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => handle_geography_remove_row(index)}>Remove</button>
                                                    </Col>
                                                </Row>)
                                        })
                                        : '')}
                                    <hr />
                                    {total_geography_percentage < 100 && (
                                        <button style={{ 'marginBottom': '1em' }} className="btn btn-outline-secondary btn-sm" onClick={handle_geography_add_row}>Add Geography</button>
                                    )}

                                    <p className="card-title-desc"> You have the option to identify the geographies associated with this position for reporting and documentation purposes. You can allocate an estimated percentage of assets linked to this position across different geographies. Should the total geographical allocation sum to less than 100%, the remainder will automatically be attributed to the 'Global Geography' category. </p>



                                    <Row>
                                        <Col lg="6" md="6">
                                            <label>Sector</label>
                                        </Col>
                                        <Col lg="3" md="3">
                                            <label>% in this sector</label>
                                        </Col>

                                    </Row>

                                    {(asset_sectors ?
                                        asset_sectors.map((row, index) => {

                                            return (
                                                <Row key={'sector_' + index} style={{ marginBottom: "1em" }}>
                                                    <Col lg="6" md="6">
                                                        <Select
                                                            value={MoondanceLayout.sectorList.find(option => option.id === row.id)}
                                                            placeholder="Select Sector (optional)"

                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={MoondanceLayout.sectorList}
                                                            onChange={(selected) => handle_sector_change(index, selected?.id)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" md="3">
                                                        <Select
                                                            value={percentageOption.find(option => option.id === parseInt(row.percentage))}
                                                            placeholder="Select percentage"

                                                            styles={MoondanceLayout.select2customStyles}
                                                            formatOptionLabel={formatOptionLabel}
                                                            options={get_available_sector_percentage_options(index)}

                                                            onChange={(selected) => handle_sector_percentage_change(index, selected?.id)}
                                                        />
                                                    </Col>
                                                    <Col lg="1">
                                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => handle_sector_remove_row(index)}>Remove</button>
                                                    </Col>
                                                </Row>)
                                        })
                                        : '')}
                                    <hr />
                                    {total_sector_percentage < 100 && (
                                        <button style={{ 'marginBottom': '1em' }} className="btn btn-outline-secondary btn-sm" onClick={handle_sector_add_row}>Add Sector</button>
                                    )}
                                    <p className="card-title-desc"> You have the option to identify the sectors associated with this position for reporting and documentation purposes. You can allocate an estimated percentage of assets linked to this position across different sectors. Should the total sector allocation sum to less than 100%, the remainder will automatically be attributed to the 'Diversified' category. </p>
                                    {(matching_position_update && Object.keys(matching_position_update).length > 0) ?
                                        <Row>
                                            <h5>Similar Positions in other accounts</h5>
                                            <p>There are similar positions available in different accounts to which you have access.
                                                You can update these positions data and model by checking the corresponding checkboxes.<br />
                                                Account, commitement, number of shares, subscription date and status will not be copied.</p>


                                            <table>
                                                {Object.entries(matching_position_update).map(([index, item]) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <input
                                                                onClick={() => (handleChangeSimilarPositionCheck(item.id))}
                                                                type="checkbox"
                                                                checked={item.update}
                                                            >
                                                            </input></td>
                                                        <td>#{item.id} - {item.account_name} - id: {item.account_id}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>


                                                    </tr>
                                                ))}
                                            </table>
                                        </Row>
                                        : ''}
                                </CardBody>
                                <CardFooter className="bg-transparent border-top  card-header ">

                                    < Form.Group as={Row} className=" mb-3d"  >
                                        <Col sm={12} md={9}>
                                            <p><b>You can save this position now and return at a later time to add additional details. Initially, the capital call and distributions model will default to standard settings. Should you wish to customize these models, simply select "<Link onClick={() => { set_show_essentials(false); set_show_details(false); set_show_model(true) }}>Edit Model</Link>" to make your adjustments.</b></p>

                                        </Col>
                                        <Col sm={12} md={3} className="d-flex align-items-center justify-content-end">
                                            <button style={{ 'marginRight': '0.5em' }} className="btn btn-outline-primary" onClick={() => { set_show_essentials(false); set_show_details(false); set_show_model(true) }}><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Edit Model</button>
                                            <button className="btn btn-danger" onClick={() => { handleSaveForm() }}><span className="mdi mdi-record"></span> Save position</button>
                                        </Col>

                                    </Form.Group>
                                </CardFooter>
                            </>
                            : '')}

                    </Card>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom  card-header ">
                            <Row>
                                <Col xl={11} sm={12} md={12} xs={12}>
                                    <h5 >Asset Model  </h5>
                                    <p >Adjust the hypothesis for this position to better estimate performance, as well as future capital calls and distributions.</p>

                                </Col>
                                <Col xl={1} sm={12} md={12} xs={12} className="d-flex  align-items-center justify-content-end " >
                                    <Button
                                        onClick={() => { set_show_model(!show_model) }}
                                        className="btn btn-link">{(!show_model ? <span><span className="mdi mdi-arrow-down-drop-circle-outline"></span> Expand</span> : <span><span className="mdi mdi-arrow-up-drop-circle-outline"></span> Collapse</span>)}</Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        {(show_model ?
                            <>
                                <CardBody>
                                    <Row>

                                        <Col sm={12} md={6} xxl={3}>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label >Investment Period</Form.Label>
                                                <InputGroup  >
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            asset_investment_period
                                                                ? asset_investment_period
                                                                : 0}
                                                        onChange={(e) => { handle_investment_period_change(parseInt(e.target.value)); }}
                                                        placeholder="Investment period"
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">Years</span>
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                            <p className="card-title-desc">The investment period is utilized by the model to distribute the commitment evenly over the relevant timeframe.</p>

                                        </Col>
                                        <Col sm={12} md={6} xxl={3}>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label>Holding Period</Form.Label>
                                                <InputGroup  >
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            asset_detention_period
                                                                ? asset_detention_period
                                                                : 0}
                                                        onChange={(e) => { handle_detention_period_change(parseInt(e.target.value)); }}
                                                        placeholder="Detention period"
                                                    />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text">
                                                            Years
                                                        </span>
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                            <p className="card-title-desc">The holding period is leveraged by the model to project future distributions, drawing on historical capital calls and anticipated multiples of invested capital (Total Value to Paid-In, TVPI).</p>
                                        </Col>
                                        <Col sm={12} md={6} xxl={3}>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label >Expected Drawdown</Form.Label>

                                                <InputGroup>

                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        value={asset_expected_drawdown ? asset_expected_drawdown : 0}
                                                        onChange={(e) => { set_asset_expected_drawdown(e.target.value); }}
                                                        placeholder="Detention period"
                                                    />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text">
                                                            %
                                                        </span>
                                                    </div>
                                                </InputGroup>


                                            </Form.Group>
                                            <p className="card-title-desc">Some positions may not require the full 100% of their committed capital to be called upon. In certain cases, management fees can lead to more than 100% of the commitment being called. You have the option to modify the expected drawdown percentage to refine the model accordingly.</p>

                                        </Col>
                                        <Col sm={12} md={6} xxl={3}>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label >Expected TVPI  {asset_expected_irr ? '(Expected IRR : ' + MoondanceLayout.formatPercentage(asset_expected_irr / 100, 2) + ')' : ''}</Form.Label>

                                                <InputGroup>

                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            asset_expected_tvpi
                                                                ? asset_expected_tvpi
                                                                : 0}
                                                        onChange={(e) => { handle_tvpi_change(parseFloat(e.target.value)); }}
                                                        placeholder="Detention period"
                                                    />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text">
                                                            X
                                                        </span>
                                                    </div>
                                                </InputGroup>


                                            </Form.Group>
                                            <p className="card-title-desc">Input the expected multiple (Total Value to Paid-In, TVPI) for this investment. The model will utilize this figure to estimate future distributions, taking into account both the capital calls and the projected multiple. Additionally, the Internal Rate of Return (IRR) is calculated based on these assumptions.</p>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {(asset_vintage_year < MoondanceLayout.currentYear() && props.editMode === 'New' ?
                                        <p ><span className="mdi mdi-alert"></span> Since the vintage date for this position has been set in the past, there is no point in modeling capital calls and distributions before adding the actual transactions that occurred previously.
                                            <br />Please save the position as is, incorporate the actual transactions, and then modify the model to accommodate the current and future years  </p>

                                        : '')}

                                    {(asset_vintage_year >= MoondanceLayout.currentYear() || props.editMode !== 'New' ?
                                        <>
                                            <Row>
                                                <Col sm="12" md="12" >
                                                    <Row style={{ marginBottom: '1em' }}>

                                                        <Col xl={10} sm={12} md={12} xs={12}>
                                                            <h5>Capital Call</h5>
                                                        </Col>

                                                        <Col xl={2} sm={12} md={12} xs={12} style={{ textAlign: 'right' }}>
                                                            <Button className="btn btn-sm  btn-outline-primary" onClick={() => { add_one_year() }}>Add One Year</Button>
                                                        </Col>
                                                    </Row>
                                                    <div className="table-responsive " >
                                                        <table className="table table-striped table-condensed">
                                                            <thead>
                                                                <tr>
                                                                    <th>Year</th>
                                                                    {asset_model_yearly_capitalcall.map((value, index) => (
                                                                        <th style={{ minWidth: '80px', height: '2em', textAlign: 'middle' }}>{asset_vintage_year + index}


                                                                        </th>
                                                                    ))}
                                                                    <th>Total

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(props.editMode !== 'New' ?
                                                                    <tr>
                                                                        <th>Actuals {asset_currency}</th>

                                                                        {asset_real_yearly_capitalcall.map((value, index) => (
                                                                            <td style={{ minWidth: '80px' }}>
                                                                                <span>{MoondanceLayout.formatNumber((value / 100) * position_commitment, 0)}</span>
                                                                            </td>
                                                                        ))}
                                                                        <th style={{ minWidth: '80px', textAlign: 'right' }}>

                                                                            <span>{MoondanceLayout.formatNumber(
                                                                                (
                                                                                    (asset_real_yearly_capitalcall.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                                                                                    / 100) * position_commitment, 0)}</span>
                                                                        </th>
                                                                    </tr>

                                                                    : ''
                                                                )}
                                                                <tr>
                                                                    <th>Expected {asset_currency}</th>

                                                                    {asset_model_yearly_capitalcall.map((value, index) => (
                                                                        <td style={{ minWidth: '80px' }}>
                                                                            <span>{MoondanceLayout.formatNumber(((value - asset_real_yearly_capitalcall[index]) / 100) * position_commitment, 0)}</span>
                                                                        </td>
                                                                    ))}
                                                                    <th style={{ minWidth: '80px', textAlign: 'right' }}>

                                                                        <span>{MoondanceLayout.formatNumber(
                                                                            ((
                                                                                (asset_model_yearly_capitalcall.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) -
                                                                                (asset_real_yearly_capitalcall.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                                                                            ) / 100) * position_commitment, 0)}</span>
                                                                    </th>


                                                                </tr>
                                                                <tr>
                                                                    <th style={{ minWidth: '80px' }}>% of Commitment<br />
                                                                        <span>Total :{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall) / 100, 2)}</span>
                                                                    </th>

                                                                    {asset_model_yearly_capitalcall.map((value, index) => (
                                                                        <td>

                                                                            <Input
                                                                                style={{ height: "2em", maxWidth: "6em" }}
                                                                                className="form-control form-control-sm "
                                                                                disabled={(asset_vintage_year + index >= MoondanceLayout.currentYear() ? false : true)}
                                                                                type="number"
                                                                                value={value} //{Math.round(value * 100) / 100}
                                                                                onChange={(e) => {
                                                                                    if (asset_vintage_year + index >= MoondanceLayout.currentYear()) {
                                                                                        handleCapitalCallPerYear('capitalCallPerYear_form-' + index, e)
                                                                                    }
                                                                                }
                                                                                }
                                                                            /><br />
                                                                            <Slider

                                                                                aria-label="Capital Call"
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    if (asset_vintage_year + index >= MoondanceLayout.currentYear()) {
                                                                                        handleCapitalCallPerYear('capitalCallPerYear_form-' + index, e)
                                                                                    }
                                                                                }
                                                                                }

                                                                                track="inverted"
                                                                                min={-100}
                                                                                max={0}
                                                                                sx={{



                                                                                    '& input[type="range"]': {
                                                                                        WebkitAppearance: 'slider-vertical',
                                                                                    },
                                                                                    color: (asset_vintage_year + index >= MoondanceLayout.currentYear() ? MoondanceLayout.colorSchemedanger : MoondanceLayout.colorSchemedark),
                                                                                    height: '8em',
                                                                                    '& .MuiSlider-thumb': {
                                                                                        width: '12px',
                                                                                        height: '12px',
                                                                                    },

                                                                                }}
                                                                                orientation="vertical" />


                                                                        </td>
                                                                    ))}
                                                                    <td></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col><Col sm="12" md="12" >
                                                    <h5>Distribution <button className="btn btn-sm  btn-outline-primary" onClick={() => { recalculateDistributions() }}>Recalculate</button></h5>
                                                    <div className="table-responsive " >
                                                        <table className="table table-striped table-condensed">
                                                            <thead>
                                                                <tr>
                                                                    <th>Year</th>
                                                                    {asset_model_yearly_distribution.map((value, index) => (
                                                                        <th>{asset_vintage_year + index}


                                                                        </th>
                                                                    ))}
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {(props.editMode !== 'New' ?
                                                                    <tr>
                                                                        <th>Actuals {asset_currency}</th>

                                                                        {asset_real_yearly_distribution.map((value, index) => (
                                                                            <td style={{ minWidth: '80px' }}>
                                                                                <span>{MoondanceLayout.formatNumber((value / 100) * position_commitment, 0)}</span>
                                                                            </td>
                                                                        ))}
                                                                        <th style={{ minWidth: '80px', textAlign: 'right' }}>

                                                                            <span>{MoondanceLayout.formatNumber((asset_real_yearly_distribution.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 100) * position_commitment, 0)}</span>
                                                                        </th>




                                                                    </tr>

                                                                    : ''
                                                                )}

                                                                <tr>
                                                                    <th style={{ minWidth: '80px' }}>Expected {asset_currency}</th>

                                                                    {asset_model_yearly_distribution.map((value, index) => (
                                                                        <td >
                                                                            <span>{MoondanceLayout.formatNumber(((value - asset_real_yearly_distribution[index]) / 100) * position_commitment, 0)}</span>
                                                                        </td>
                                                                    ))}
                                                                    <th style={{ minWidth: '80px', textAlign: 'right' }}>

                                                                        <span>{MoondanceLayout.formatNumber(
                                                                            ((
                                                                                (asset_model_yearly_distribution.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) -
                                                                                (asset_real_yearly_distribution.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                                                                            ) / 100) * position_commitment, 0)}</span>
                                                                    </th>

                                                                </tr>
                                                                <tr>
                                                                    <td ><b>% of Commitment</b><br /><span>{MoondanceLayout.formatPercentage((asset_sum_model_yearly_distribution) / 100, 2)} </span><br /><br />

                                                                        <span><b>% of Called</b><br />{MoondanceLayout.formatPercentage((asset_sum_model_yearly_distribution / -asset_sum_model_yearly_capitalcall), 2)} </span>

                                                                    </td>

                                                                    {asset_model_yearly_distribution.map((value, index) => (
                                                                        <td style={{ minWidth: '80px' }} >
                                                                            <Input
                                                                                style={{ height: "2em", maxWidth: "6em" }}
                                                                                className="form-control form-control-sm "
                                                                                disabled={(asset_vintage_year + index >= MoondanceLayout.currentYear() ? false : true)}
                                                                                type="number"
                                                                                value={value} //{Math.round(value * 100) / 100}
                                                                                onChange={(e) => {
                                                                                    if (asset_vintage_year + index >= MoondanceLayout.currentYear()) {
                                                                                        let min = 0
                                                                                        if (asset_vintage_year + index === MoondanceLayout.currentYear()) {
                                                                                            min = asset_real_yearly_distribution[index]

                                                                                        }
                                                                                        if (e.target.value >= min) {
                                                                                            handleDistributionPerYear('distributionPerYear_form-' + index, e)
                                                                                        }
                                                                                    }
                                                                                }}

                                                                            /><br />
                                                                            <Slider

                                                                                aria-label="Capital Call"
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    if (asset_vintage_year + index >= MoondanceLayout.currentYear()) {
                                                                                        let min = 0
                                                                                        if (asset_vintage_year + index === MoondanceLayout.currentYear()) {
                                                                                            min = asset_real_yearly_distribution[index]

                                                                                        }
                                                                                        if (e.target.value >= min) {
                                                                                            handleDistributionPerYear('distributionPerYear_form-' + index, e)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                min={0}
                                                                                max={150}
                                                                                sx={{
                                                                                    '.MuiSlider-rail': {
                                                                                        color: '#DDDDDD'
                                                                                    },
                                                                                    '& input[type="range"]': {
                                                                                        WebkitAppearance: 'slider-vertical',
                                                                                    },
                                                                                    color: (asset_vintage_year + index >= MoondanceLayout.currentYear() ? MoondanceLayout.colorSchemesuccess : '#DDDDDD'),
                                                                                    height: '8em',
                                                                                    '& .MuiSlider-thumb': {
                                                                                        width: '12px',
                                                                                        height: '12px',
                                                                                    },

                                                                                }}
                                                                                orientation="vertical" />


                                                                        </td>

                                                                    ))}
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution >= 0 ? (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="6">
                                                            <table className="table table-striped table-condensed">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Summary</th>
                                                                        <th style={{ textAlign: 'right', marginRight: '1em' }}> % of Commitment</th>
                                                                        <th style={{ textAlign: 'right', marginRight: '1em' }}>{asset_currency}</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>Past Capital calls</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage(-asset_sum_real_yearly_capitalcall / 100, 2)}</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency(-asset_sum_real_yearly_capitalcall * position_commitment / 100, asset_currency)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Expected Future Capital calls</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall + asset_sum_real_yearly_capitalcall) / 100, 2)}</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency((-asset_sum_model_yearly_capitalcall + asset_sum_real_yearly_capitalcall) * position_commitment / 100, asset_currency)}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Total Calls</b></td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall) / 100, 2)}   </td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency(-asset_sum_model_yearly_capitalcall * position_commitment / 100, asset_currency)}</td>

                                                                    </tr>

                                                                    <tr>
                                                                        <td>Past Distributions</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage(asset_sum_real_yearly_distribution / 100, 2)}</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency(asset_sum_real_yearly_distribution * position_commitment / 100, asset_currency)}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td>Expected Future Distributions</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage((asset_sum_model_yearly_distribution - asset_sum_real_yearly_distribution) / 100, 2)}</td>

                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency((asset_sum_model_yearly_distribution - asset_sum_real_yearly_distribution) * position_commitment / 100, asset_currency)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Total Distributions</b></td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage((asset_sum_model_yearly_distribution) / 100, 2)}</td>
                                                                        <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency(asset_sum_model_yearly_distribution * position_commitment / 100, asset_currency)}</td>

                                                                    </tr>

                                                                    {(position_nav_in_asset_currency > 0 ?

                                                                        <tr>
                                                                            <td><b>Last NAV</b></td>
                                                                            <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatPercentage((position_nav_in_asset_currency / position_commitment), 2)}</td>
                                                                            <td style={{ textAlign: 'right', marginRight: '1em' }}>{MoondanceLayout.formatCurrency(position_nav_in_asset_currency, asset_currency)}</td>

                                                                        </tr>
                                                                        : '')}

                                                                </tbody>
                                                            </table>
                                                        </Col>

                                                        <Col sm="12" md="6">
                                                            <h6>Model Check</h6>

                                                            {(Math.abs(Math.round((parseFloat(asset_expected_drawdown) + parseFloat(asset_sum_model_yearly_capitalcall)))) < 0.5 ?
                                                                <p ><span className="mdi mdi-thumb-up"></span> Modeled capital calls are aligned with anticipated drawdowns.</p>
                                                                :
                                                                <>
                                                                    {(asset_expected_drawdown - (-asset_sum_model_yearly_capitalcall) > 0 ?

                                                                        <p ><span className="mdi mdi-alert"></span> Modeled capital calls fall short of the anticipated drawdowns.</p>
                                                                        : <p ><span className="mdi mdi-alert"></span> Modeled capital calls are exceeding anticipated drawdowns.</p>
                                                                    )}
                                                                    <ul>
                                                                        {((asset_sum_model_yearly_capitalcall - asset_sum_real_yearly_capitalcall) !== 0 ?
                                                                            <li style={{ marginBottom: '1em' }}><Link onClick={() => { handleCapitalCallMatchDrawdown('calls') }}>Adjust future calls to match expected drawdown</Link></li>
                                                                            : '')}

                                                                        <li><Link onClick={() => { handleCapitalCallMatchDrawdown('draw') }}>Adjust expected drawdown to match future calls</Link></li>
                                                                    </ul>
                                                                </>
                                                            )}
                                                            {(Math.abs(asset_sum_model_yearly_distribution - (asset_expected_drawdown / 100) * asset_expected_tvpi * 100) < 0.1 ?

                                                                <p ><span className="mdi mdi-thumb-up"></span> The modeled distributions align with the product of expected drawdowns and Total Value to Paid-In (TVPI)</p>
                                                                :

                                                                <>
                                                                    {((asset_sum_model_yearly_distribution) > (-asset_sum_model_yearly_capitalcall * asset_expected_tvpi) ?

                                                                        <p ><span className="mdi mdi-alert"></span> The modeled distributions are <b>exceeding</b> the expected Total Value to Paid-In (TVPI)</p>
                                                                        :

                                                                        <p><span className="mdi mdi-alert"></span> The modeled distributions are <b>below</b> the expected Total Value to Paid-In (TVPI)</p>
                                                                    )}
                                                                    {(asset_sum_model_yearly_distribution > 0 ?
                                                                        <>
                                                                            <p>You can fix this error by choosing one of the following options :</p>
                                                                            <ul>
                                                                                {(asset_sum_model_yearly_distribution > 0 ?
                                                                                    <li style={{ marginBottom: '1em' }}><Link onClick={() => { handleDistributionsMatchTvpi('distribution') }}>Adjust future distributions to match the expected TVPI ({MoondanceLayout.formatNumber(asset_expected_tvpi, 2)}x)</Link></li>
                                                                                    : <li><b>Set at least 1 future distribution </b></li>)}
                                                                                <li><Link onClick={() => { handleDistributionsMatchTvpi('TVPI') }}>Adjust the expected TVPI to match future distributions</Link></li>

                                                                            </ul>
                                                                            {(position_nav_in_asset_currency > 0 && (asset_sum_model_yearly_distribution - asset_sum_real_yearly_distribution) * position_commitment / 100 < position_nav_in_asset_currency) ?

                                                                                <>
                                                                                    <p ><span className="mdi mdi-alert"></span> The modeled future distributions are below the last registered Net Asset Value (NAV:  {MoondanceLayout.formatCurrency(position_nav_in_asset_currency, asset_currency)}). This scenario is unlikely unless a future reduction in value is anticipated. </p>
                                                                                    <ul>
                                                                                        <li><Link onClick={() => { handleDistributionsMatchTvpi('nav') }}>Adjust future distributions to match last known NAV</Link></li>

                                                                                    </ul>
                                                                                </>
                                                                                : <p ><span className="mdi mdi-thumb-up"></span> The modeled distributions is at least equal to the last registered Net Asset Value (NAV:  {MoondanceLayout.formatCurrency(position_nav_in_asset_currency, asset_currency)}).</p>
                                                                            }
                                                                        </>
                                                                        : <p><span className="mdi mdi-alert"></span> <b>The system cannot adjust future distributions if they are all set to 0. Please set at least one future distribution to a non-zero value.</b></p>)}
                                                                </>

                                                            )}





                                                        </Col>
                                                    </Row>


                                                </>
                                            ) : ''}
                                        </> : '')}

                                    {(matching_position_update && Object.keys(matching_position_update).length > 0) ?
                                        <Row>
                                            <h5>Similar Positions in other accounts</h5>
                                            <p>There are similar positions available in different accounts to which you have access.
                                                You can update these positions data and model by checking the corresponding checkboxes.<br />
                                                Account, commitement, number of shares, subscription date and status will not be copied.</p>


                                            <table>
                                                {Object.entries(matching_position_update).map(([index, item]) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <input
                                                                onClick={() => (handleChangeSimilarPositionCheck(item.id))}
                                                                type="checkbox"
                                                                checked={item.update}
                                                            >
                                                            </input></td>
                                                        <td>#{item.id} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>


                                                    </tr>
                                                ))}
                                            </table>
                                        </Row>
                                        : ''}
                                </CardBody>

                                <CardFooter className="bg-transparent border-top  card-header ">
                                    < Form.Group as={Row} className=" mb-3d"  >
                                        <Col sm={12} md={12} className="d-flex align-items-center justify-content-end">
                                            <button className="btn btn-danger" onClick={() => { handleSaveForm() }}><span className="mdi mdi-record"></span> Save position</button>
                                        </Col>

                                    </Form.Group>
                                </CardFooter>
                            </>
                            : '')}



                    </Card >
                </Row >

            }






        </React.Fragment >


    );
    /*const [asset_first_year, set_asset_first_year] = useState((props.data.summary.asset_first_year ? props.data.summary.asset_first_year : 0));
        const [asset_expected_drawdown, set_asset_expected_drawdown] = useState((props.data.summary.asset_expected_drawdown ? props.data.summary.asset_expected_drawdown : 0));
        const [asset_expected_irr, set_asset_expected_irr] = useState((props.data.summary.asset_expected_irr ? props.data.summary.asset_expected_irr : 0));
        const [asset_expected_tvpi, set_asset_expected_tvpi] = useState((props.data.summary.asset_expected_tvpi ? props.data.summary.asset_expected_tvpi : 0));
        const [asset_intermediate, set_asset_intermediate] = useState((props.data.summary.asset_intermediate ? props.data.summary.asset_intermediate : 0));
        co*/


    /*{matching_position_update && matching_position_update.length > 0 ?
                                            <Row>
                                                <h5>Similar Positions in other accounts</h5>
                                                <p>There are similar positions available in different accounts to which you have access.
                                                    You can update these positions data and model by checking the corresponding checkboxes.<br />
                                                    Account, commitement, number of shares, subscription date and status will not be copied.</p>
    
    
                                                <table>
                                                    {matching_position_update.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <input
                                                                    onClick={() => (handleChangeSimilarPositionCheck(item.id))}
                                                                    type="checkbox"
                                                                    checked={matching_position_update[item.id].update}
                                                                >
                                                                </input></td>
                                                            <td>#{item.id} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>
    
    
                                                        </tr>
                                                    ))}
                                                </table>
                                            </Row>
                                            : ''}*/
}



export default EditPositionForm


/*

     <Col sm="12" md="4" >
                                    <ReactApexChart
                                        key={'chart'}
                                        options={{
                                            xaxis: {
                                                type: 'category',
                                                categories: Array.from({ length: asset_model_yearly_capitalcall.length }, (_, i) => asset_vintage_year + i),
                                                convertedCatToNumeric: false,
                                            },
                                            chart: {
                                                toolbar: {
                                                    show: false // this hides the toolbar
                                                },

                                                stacked: false
                                            },
                                            fill: {
                                                colors: [MoondanceLayout.colorSchemedanger, MoondanceLayout.colorSchemesuccess],
                                            },
                                            stroke: {
                                                colors: [MoondanceLayout.colorSchemedanger, MoondanceLayout.colorSchemesuccess],
                                                width: [0, 0]
                                            },

                                        }}
                                        series={[
                                            {
                                                name: 'Model Capital Call',
                                                type: 'column',
                                                data: asset_model_yearly_capitalcall,

                                            },
                                            {
                                                name: 'Model distribution',
                                                type: 'column',
                                                data: asset_model_yearly_distribution
                                            },
                                        ]}
                                        width="100%"
                                        height={250}
                                        className="apex-charts"
                                    />

                                </Col>*/